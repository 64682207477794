import React from "react";
import { useKeycloak } from "@react-keycloak/web";

const NavBar = () => {
  const { keycloak, initialized } = useKeycloak();

  return (
    <nav style={{ color: "black", backgroundColor: "gray" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>CLIENTS TEST</h1>
        <ul
          style={{
            display: "flex",
            width: "200px",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/secured">Secured Page</a>
          </li>
        </ul>
        <div>
          {!keycloak.authenticated && (
            <button
              type="button"
              className="text-blue-800"
              onClick={() => keycloak.login()}
            >
              Login
            </button>
          )}

          {!!keycloak.authenticated && (
            <button
              type="button"
              className="text-blue-800"
              onClick={() => keycloak.logout()}
            >
              Logout ({keycloak.tokenParsed.preferred_username})
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

import Keycloak from "keycloak-js";

const keycloak = Keycloak({
  url: "https://authdev.ipxon.net/auth/",
  realm: "whmcs-dev",
  "ssl-required": "external",
  resource: "clients",
  "public-client": true,
  "confidential-port": 0,
  clientId: "clients",
});

console.log("prueba");
console.log(keycloak);
console.log("prueb2");
export default keycloak;

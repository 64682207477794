import { useKeycloak } from "@react-keycloak/web";
import React from "react";

const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  if (!initialized) {
    return <div>Loading....</div>;
  }
  console.log(keycloak, initialized);
  console.log(keycloak);

  const isLoggedIn = keycloak.authenticated;
  console.log(isLoggedIn);

  return isLoggedIn ? children : null;
};

export default PrivateRoute;

import React from "react";

const SecuredPage = () => {
  return (
    <div>
      <h1>Welcome to the Protected Page.</h1>
    </div>
  );
};

export default SecuredPage;
